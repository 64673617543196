import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
  }
  logout() {
    this.authService.removeAccessToken()
    this.router.navigate(['/login']);
  }
}
