import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import { Observer } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isPasswordVisible = false;
  loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]], // Adjust the minimum length as needed
  });
  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    if (this.authService.isAuthenticated()) {
      const payload = this.authService.getDecodedAccessToken()
      if (payload?.company?._id) this.router.navigate(['/private/dashboard']);
      else if (payload?.candidate?._id) this.router.navigate(['/candidate/dashboard-candidate']);
      else {
        console.error('something went wrong + back() + constructor' + LoginComponent.name)
      }
    }
  }

  paramValue: string = ""
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // Access route parameters here
      const paramValue = params['type']; // replace 'paramName' with the actual parameter name
      if (!paramValue) {
        console.error('something went wrong + back()' + LoginComponent.name)
        this.location.back()
      }
      // Perform actions based on the parameter value
      console.log('Route parameter value:', paramValue);
      this.paramValue = paramValue
    });
  }

  navigateToSignUp() {
    this.router.navigate(['/auth/signup/' + this.paramValue]);
  }

  navigateToHomePage() {
    this.router.navigate(['/public/hero']);
  }

  logIn(event: Event) {
    event.preventDefault();
    console.log('Form', this.loginForm.value);

    const observer: Observer<any> = {
      next: (authResp: { accessToken?: string, company?: boolean, success?: boolean }) => {
        if (authResp?.accessToken && authResp?.success) {
          this.authService.setAccessToken(authResp.accessToken);
          if (authResp.company) {
            this.router.navigate(['/private/dashboard']);
          } else {
            this.router.navigate(['/candidate/dashboard-candidate']);
          }
        } else {
          throw new Error("There's something wrong!");
        }
      },
      error: (error) => {
        console.error("Error during login:", error);
      },
      complete: () => {
        // Optional: Handle completion if needed
      }
    };

    this.authService.login(this.loginForm.value['email'], this.loginForm.value['password']).subscribe(observer);
  }
}
