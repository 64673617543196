import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomRatingComponent } from "./component/custom-rating/custom-rating.component";
import { RatingModule } from "ngx-bootstrap/rating";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { CalendarModule } from "angular-calendar";
import { FlatpickrModule } from "angularx-flatpickr";
import { FullCalendarModule } from "@fullcalendar/angular";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { MaterialModule } from "../material/material.module";
import { SideBarComponent } from './layout/side-bar/side-bar.component';
import { HeaderComponent } from './layout/header/header.component';



@NgModule({
  declarations: [CustomRatingComponent, ConfirmationModalComponent, SideBarComponent, HeaderComponent],
  imports: [
    CommonModule,
    RatingModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    CalendarModule,
    FlatpickrModule,
    FullCalendarModule,
    BsDatepickerModule,
    RatingModule.forRoot(),
    TooltipModule,
    PaginationModule,
    MaterialModule
  ],
  exports: [CustomRatingComponent, ConfirmationModalComponent, SideBarComponent, HeaderComponent]
})
export class SharedModule { }
