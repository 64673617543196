<div class="login-section py-4 px-5 position-relative">
  <img class="login-bg" src="./assets/img/login-bg.png" alt="bg" />
  <div class="hero-header d-flex justify-content-between pb-3">
    <img src="./assets/img/logo-colored.png" alt="logo" class="cursor-pointer" (click)=navigateToHomePage() />
    <div class="d-flex pe-5">
      <button
        type="button"
        class="btn btn-outline-primary mx-2 font-semiBold fs-15"
        (click)="navigateToSignUp()"
      >
        Sign up
      </button>
    </div>
  </div>
  <form class="login-forms" [formGroup]="loginForm" (ngSubmit)="logIn($event)">
    <div class="about-bloc px-5 pb-4 pt-5">
      <h2 class="text-center font-bold fs-27 text-primary py-3">
        Welcome Back !
      </h2>
      <p class="font-regular fs-19 text-center">
        Please enter your login details.
      </p>
      <div class="row px-5">
        <div class="col-12 mb-3">
          <label for="EmailAdress" class="form-label ps-3">Email adress</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="EmailAdress"
              placeholder="Email adress"
              formControlName="email"
            />
            <span class="input-group-text"
              ><em class="icon-mail-2 fs-20"></em
            ></span>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="password" class="form-label ps-3">Password</label>
          <div class="input-group">
            <input
              [type]="isPasswordVisible ? 'text' : 'password'"
              class="form-control"
              id="password"
              placeholder="Password"
              formControlName="password"
            />
            <span (click)="togglePasswordVisibility()" class="input-group-text">
              <em
                class="text-gray"
                [class]="
                  isPasswordVisible ? 'icon-lock fs-23' : 'icon-eye fs-15'
                "
              ></em>
            </span>
          </div>
        </div>
        <div class="col-6 mb-3 form-check ps-2">
          <div class="checkbox d-flex align-items-center cursor-pointer">
            <div>
              <input
                id="terms"
                name="terms"
                class="checkbox-custom m-0"
                type="checkbox"
              />
              <label for="terms" class="checkbox-custom-label pl-0"></label>
            </div>
            <label class="form-check-label cursor-pointer" for="terms">
              keep me logged in
            </label>
          </div>
        </div>
        <div class="col-6 mb-3 form-check ps-2 d-flex justify-content-end">
          <a
            class="font-regular text-right fs-13 text-decoration-none text-black"
            href="#"
            >Forget Passwoard ?</a
          >
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-center pb-2 pt-4">
          <button
            type="submit"
            class="btn btn-primary px-3 ms-3 font-semiBold fs-15"
           
          >
            log in
          </button>
        </div>
        <!-- <h3 class="text-center py-3 font-regular fs-13">Or</h3>
        <div class="d-flex justify-content-center pb-3">
          <button
            type="button"
            class="btn btn-outline-primary px-3 ms-3 font-semiBold fs-15"
            
          >
            Sinup with LinkedIn
          </button>
        </div> -->
      </div>
    </div>
  </form>
</div>
