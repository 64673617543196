<div class="login-section py-4 px-5 position-relative">
  <img class="login-bg" src="./assets/img/login-bg.png" alt="bg" />
  <div class="hero-header d-flex justify-content-between pb-3">
    <img src="./assets/img/logo-colored.png" class="cursor-pointer" alt="logo" (click)="navigateToHomePage()" />
    <div class="d-flex pe-5">
      <button type="button" class="btn btn-outline-primary mx-2 font-semiBold fs-15" (click)="navigateToHomePage()">
        Homepage
      </button>
    </div>
  </div>
  <form class="login-forms">
    <div class="about-bloc px-5 pb-4 pt-5">
      <h2 class="text-center font-bold fs-27 text-primary py-3">
        Welcome Back !
      </h2>
      <p class="font-regular fs-19 text-center">
        Are you here to explore job opportunities or to find the perfect candidate for your team? Let us know by
        choosing your role:
      </p>
      <div class="d-flex" style="justify-content: center;">
        <button class="btn btn-light text-primary  post-btn me-2 me-lg-5" (click)="navigateTo('company')">
          <em class="icon-company fs-65"></em>
          <span class="font-bold fs-24 pt-3">
            Company
          </span>
        </button>
        <button class="btn btn-light text-primary  post-btn ms-2 ms-lg-4" (click)="navigateTo('candidate')">
          <em class="icon-user fs-45"></em>
          <span class="font-bold fs-24 pt-3">
            Candidate
          </span>
        </button>
      </div>
    </div>
  </form>
</div>