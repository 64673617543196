// user.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICandidate } from '../models/candidate.models';

@Injectable({
    providedIn: 'root',
})
export class CandidateService {
    private apiUrl = `${environment.apiUrl}/user`; // Replace with your NestJS API URL

    constructor(private http: HttpClient) { }

    uploadCandidateImage(candidateId: string | undefined, picture: File): Observable<any> | any {
        if (candidateId) {
            const formData: FormData = new FormData();
            formData.append('picture', picture, picture.name);

            return this.http.post<any>(
                `${this.apiUrl}/${candidateId}/upload/profileImg`,
                formData
            );
        }
    }

    getAllUsers(skip: number, limit: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/all?limit=${limit}&skip=${skip}`);
    }

    getProfile(): Observable<ICandidate> {
        const url = `${this.apiUrl}/profile`;
        return this.http.get<ICandidate>(url);
    }

    updateUserWithFile(file: File, updateUserDto: Partial<ICandidate>): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('profileImg', file);
        formData.append('updateUserDto', JSON.stringify(updateUserDto));

        return this.http.put(`${this.apiUrl}/updateWithFile`, formData);
    }

    update(updateUserDto: Partial<ICandidate>): Observable<any> {
        return this.http.put(`${this.apiUrl}/update`, updateUserDto);
    }
}
