import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { COUNTRIES } from './countries.data';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  currentStep: number = 1;
  isPasswordVisible1 = false;
  isPasswordVisible2 = false;
  registerForm: FormGroup = this.fb.group({
    adminName: ['', [Validators.required, Validators.minLength(6)]],
    phoneNumber: ['', [Validators.required, Validators.minLength(8)]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: [
      '',
      [Validators.required, this.matchPasswords.bind(this)],
    ],
    country: ['', [Validators.required]],
    state: ['', [Validators.required]],
    zipCode: ['', [Validators.required]],
    companyName: ['', [Validators.required]],
  });

  getCountries() {
    return COUNTRIES
  }
  navigateToHomePage() {
    this.router.navigate(['/public/hero']);
  }
  matchPasswords(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.registerForm) {
      const password = this.registerForm.get('password')?.value;
      const confirmPassword = control.value;

      if (password === confirmPassword) {
        return null; // Valid
      } else {
        return { mismatch: true }; // Invalid
      }

    } else return null
  }
  togglePasswordVisibility(inputNumber: number) {
    if (inputNumber === 1) {
      this.isPasswordVisible1 = !this.isPasswordVisible1;
    } else if (inputNumber === 2) {
      this.isPasswordVisible2 = !this.isPasswordVisible2;
    }
  }
  constructor(
    private authService: AuthService,
    public router: Router,
    private fb: FormBuilder) {
    if (this.authService.isAuthenticated()) {
      const payload = this.authService.getDecodedAccessToken()
      if (payload?.company?._id) this.router.navigate(['/private/dashboard']);
      else if (payload?.candidate?._id) this.router.navigate(['/candidate/dashboard-candidate']);
      else {
        console.error('something went wrong + back() + constructor' + SignUpComponent.name)
      }
    }
  }

  ngOnInit(): void {
  }
  navigateToLogin() {
    this.router.navigate(['/auth/login/company']);
  }

  registerCompany(companyData: any) {
    const formData = new FormData();
    formData.append('adminName', companyData.adminName);
    formData.append('companyName', companyData.companyName);
    formData.append('password', companyData.password);
    formData.append('email', companyData.email);
    formData.append('phoneNumber', companyData.phoneNumber);
    formData.append('country', companyData.country);
    formData.append('state', companyData.state);
    formData.append('zipCode', companyData.zipCode);

    // Add profileImg to formData if available
    if (this.logo) {
      formData.append('logo', this.logo);
    }
    this.authService.registerCompany(companyData).subscribe({
      next: (response) => {
        // Handle success
        console.log('Company registration successful', response);
        this.currentStep = this.currentStep + 1;
      },
      error: (error) => {
        // Handle error
        console.error('Company registration failed', error);
      },
      complete: () => {
        // Optional: Handle completion if needed
      }
    });
  }

  logo: any = null
  logoPreview: any = null
  handleLogoUpload(event: any): void {
    const file = event.target.files[0];
    if (file) {
      console.log('Uploaded file:', file);
      this.logo = file
      const reader = new FileReader();
      reader.onload = (e) => {
        this.logoPreview = e.target?.result;
      };
      reader.readAsDataURL(file);
    }
  }
  nextStep() {
    if (this.currentStep === 2) {
      console.log("this.registerForm.value : ", this.registerForm.value)
      const companyData = this.registerForm.value;

      this.registerCompany(companyData)
    }
    if (this.currentStep < 2) {
      this.currentStep = this.currentStep + 1;
    }
  }
  prevStep() {
    if (this.currentStep > 1) {
      this.currentStep = this.currentStep - 1;
    }
  }
}
