<div class="signUp-section pt-4 px-5 position-relative">
  <img class="sign-up-bg" [src]="
        currentStep === 1
          ? './assets/img/sign-up-1.png'
          : './assets/img/sign-up-2.png'
      " alt="bg" />
  <div class="hero-header d-flex justify-content-between">
    <img src="./assets/img/logo-white.png" alt="logo" class="cursor-pointer" (click)=navigateToHomePage() />
    <div class="d-flex pe-5">
      <button class="btn btn-primary font-bold fs-15" type="submit" (click)="navigateToLogin()">
        Login
      </button>
    </div>
  </div>
  <form class="sign-up-forms pt-lg-2" [formGroup]="registerForm">
    <div class="blur-bloc px-5 py-5">
      <div class="first-bloc" *ngIf="currentStep === 1">
        <h2 class="text-center font-bold fs-27 text-primary">Create an account</h2>
        <div class="row px-5">
          <div class="col-6 pe-2 mb-3">
            <label for="FirstName" class="form-label ps-3">First name</label>
            <div class="input-group">
              <input type="text" class="form-control" id="FirstName" placeholder="First name"
                formControlName="firstName" />
              <span class="input-group-text"><em class="icon-user fs-20"></em></span>
            </div>
          </div>
          <div class="col-6 pe-2 mb-3">
            <label for="LastName" class="form-label ps-3">Last name</label>
            <div class="input-group">
              <input type="text" class="form-control" id="LastName" placeholder="Last name"
                formControlName="lastName" />
              <span class="input-group-text"><em class="icon-mail fs-20"></em></span>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label for="EmailAdress" class="form-label ps-3">Email address</label>
            <div class="input-group">
              <input type="text" class="form-control" id="EmailAdress" placeholder="Email address"
                formControlName="email" />
              <span class="input-group-text"><em class="icon-mail-2 fs-20"></em></span>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label for="password" class="form-label ps-3">Password</label>
            <div class="input-group">
              <input [type]="isPasswordVisible1 ? 'text' : 'password'" class="form-control" id="password"
                placeholder="Password" formControlName="password" />
              <span (click)="togglePasswordVisibility(1)" class="input-group-text">
                <em class="text-gray" [class]="
                      isPasswordVisible1 ? 'icon-lock fs-23' : 'icon-eye fs-15'
                    "></em>
              </span>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label for="RetypePassword" class="form-label ps-3">Retype password</label>
            <div class="input-group">
              <input [type]="isPasswordVisible2 ? 'text' : 'password'" class="form-control" id="RetypePassword"
                placeholder="Retype password" formControlName="confirmPassword" />
              <span (click)="togglePasswordVisibility(2)" class="input-group-text">
                <em class="text-gray" [class]="
                      isPasswordVisible2 ? 'icon-lock fs-23' : 'icon-eye fs-15'
                    "></em>
              </span>
            </div>
          </div>
          <div class="col-12 mb-3 form-check ps-2">
            <div class="checkbox d-flex align-items-center cursor-pointer">
              <div>
                <input id="terms" name="terms" class="checkbox-custom m-0" type="checkbox" />
                <label for="terms" class="checkbox-custom-label pl-0"></label>
              </div>
              <label class="form-check-label" for="terms">
                I have read, understand, accept and agree to be bound by the
                following
                <a href=""> terms and conditions.</a>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="second-bloc" *ngIf="currentStep === 2">
        <div class="d-flex justify-content-center">
          <div class="profile-picture mb-3">
            <label for="signupcandilogoUpload" class="upload-label">
              <input type="file" id="signupcandilogoUpload" (change)="handleLogoUpload($event)" accept="image/*"
                style="display: none">
              <em *ngIf="!logoPreview" class="icon-camera fs-90 text-light"></em>
              <img *ngIf="logoPreview" [src]="logoPreview" alt="Logo Preview" class="logo-preview">
              <span class="edit d-flex justify-content-center align-items-center">
                <em class="icon-pen fs-17"></em>
              </span>
            </label>
          </div>
        </div>

        <div class="row px-5">
          <div class="col-45 pe-2 mb-3">
            <label for="Phone" class="form-label ps-3">Phone number</label>
            <div class="input-group">
              <input type="text" class="form-control" id="Phone" placeholder="Phone number"
                formControlName="phoneNumber" />
            </div>
          </div>
          <div class="col-12 pe-2 mb-3">
            <label class="form-label ps-3">Country</label>
            <div class="custom-select">
              <select class="form-select custom-select" aria-label="Default select example" formControlName="country">
                <option value="1" disabled [defaultSelected]="currentStep">
                  Country
                </option>
                <option *ngFor="let country of getCountries()" [value]="country">{{country}}</option>
              </select>
            </div>
          </div>
          <div class="col-6 pe-2 mb-3">
            <label class="form-label ps-3">State</label>
            <div class="input-group">
              <input type="text" class="form-control" id="State" placeholder="State" formControlName="state" />
            </div>
          </div>
          <div class="col-6 mb-3">
            <label class="form-label ps-3">Zip Code</label>
            <div class="input-group">
              <input type="text" class="form-control" id="ZipCode" placeholder="Zip Code" formControlName="zipCode" />
            </div>
          </div>
        </div>
      </div>
      <div class="success" *ngIf="currentStep === 3">
        <div class="d-flex justify-content-center pt-5 mt-5 pb-4">
          <em class="icon-success fs-90 text-primary"></em>
        </div>
        <div class="mb-3">
          <h2 class="font-bold fs-64 text-primary text-center pb-3">
            Success !
          </h2>
          <p class="font-regular">
            Your account has been successfully created
          </p>
        </div>
      </div>

      <div class="d-flex justify-content-center pt-3">
        <button class="btn btn-danger me-3 px-3 font-semiBold fs-15" type="submit" *ngIf="currentStep < 3"
          (click)="prevStep()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary px-3 ms-3 font-semiBold fs-15" *ngIf="currentStep === 1"
          (click)="nextStep()">
          Next
        </button>
        <button type="button" class="btn btn-primary px-3 ms-3 font-semiBold fs-15" *ngIf="currentStep === 2"
          (click)="nextStep()">
          Signup
        </button>
        <button class="btn btn-primary mt-3 px-3 font-semiBold fs-15" type="submit" (click)=navigateToLogin()
          *ngIf="currentStep === 3">
          ok
        </button>
      </div>
    </div>
  </form>
</div>