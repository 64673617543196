<div class="hero-section py-4 px-5">
  <div class="hero-header d-flex justify-content-between pb-4">
    <img src="./assets/img/logo-white.png" alt="logo">
    <div class="d-flex">
      <button class="btn btn-white bg-white text-primary mx-2 font-bold fs-15" type="submit" (click)="navigateToAuth('login')">Login</button>
      <button type="button" class="btn btn-outline-light mx-2 font-bold fs-15" (click)="navigateToAuth('signup')">Sign up</button>
    </div>
  </div>
  <div class="row m-0 pt-4">
    <div class="col-12 col-md-6 col-lg-7 pt-lg-5 pe-5">
      <h2 class="fs-65 text-white font-bold pb-5 pe-3">HIRE THE <span class="text-danger">RIGHT PERSON</span> <br> FOR YOUR BUSINESS <span class="text-danger">.</span></h2>
      <p class="font-regular text-white fs-20 pb-4">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus assumenda beatae blanditiis, doloremque ea expedita praesentium quis tempore! Accusantium excepturi incidunt quasi quod similique temporibus voluptatem. Blanditiis dicta explicabo fugiat?
      </p>
     <div class="d-flex">
       <button class="btn btn-light text-primary  post-btn me-2 me-lg-5" (click)="navigateToLogin('company')">
         <em class="icon-company fs-65"></em>
         <span class="font-bold fs-24 pt-3" >
           Company
         </span>
       </button>
       <button class="btn btn-light text-primary  post-btn ms-2 ms-lg-4" (click)="navigateToLogin('candidate')">
         <em class="icon-user fs-45"></em>
         <span class="font-bold fs-24 pt-3" >
           Candidate
         </span>
       </button>
     </div>
    </div>
    <div class="col-12 col-md-6 col-lg-5">
      <div class="about-bloc px-4">
        <h2 class="text-center text-white py-5">About Us</h2>
        <p class="text-white font-regular fs-20">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. At autem consectetur cupiditate debitis dicta dolores ea excepturi facere facilis impedit ipsam officiis placeat porro quam quas, quidem rerum sit unde?
        </p>
        <p class="text-white font-regular fs-20">
           Lorem ipsum dolor sit amet, consectetur adipisicing elit. At autem consectetur cupiditate debitis dicta dolores ea excepturi facere facilis impedit ipsam officiis placeat porro quam quas, quidem rerum sit unde?
        </p>
        <div class="d-flex pt-4 justify-content-center">
          <div class="font-bold text-white fs-70 red-dot me-4">
            <span>
                 13K
            </span>

          </div>
          <div class="font-bold text-white fs-70 red-dot ms-4">
             <span>
                 25k
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
