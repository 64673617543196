<div class="nav-side-menu" *ngIf="candidate||company">
  <div>
    <img src="./assets/img/logo-colored.png" alt="logo" class="cursor-pointer" (click)="goToDashboard()">
    <nav id="side-nav" class="side-nav">
      <div class="nav-element pt-5 mt-lg-5">
        <div *ngFor="let item of navItems">
          <a
            class="my-3 nav-link"
            (click)="onLinkClick(item.link)"
            [class.active-link]="isActive(item.link)">
            <span class="sidenav-items">
              <em class="{{ item.iconClass }}"></em>
            </span>
            <p class="font-medium fs-14 text-dark-gray mb-0 text-center pt-2">{{item.title}}</p>
          </a>
        </div>

      </div>
    </nav>
  </div>
  <div>
    <ng-container *ngIf="checkPictureExistance();else elseNoImageBloc;">
      <img class="cursor-pointer logo-preview" [src]="getPicture()" alt="user" (click)="goToProfile()">
    </ng-container>
    <ng-template #elseNoImageBloc>
      <img src="./assets/img/user.png" alt="logo" class="cursor-pointer" (click)="goToProfile()">
    </ng-template>
  </div>
</div>
