import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthChoiceComponent } from './auth-choice/auth-choice.component';
import { SignupCandidateComponent } from './signup-candidate/signup-candidate.component';

@NgModule({
  declarations: [LoginComponent, SignUpComponent, AuthChoiceComponent, SignupCandidateComponent],
  imports: [CommonModule, ReactiveFormsModule,HttpClientModule],
})
export class AuthModule {}
