import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./auth/login/login.component";
import { HeroComponent } from "./public/hero/hero.component";
import { SignUpComponent } from "./auth/sign-up/sign-up.component";
import { NgClass } from "@angular/common";
import { PrivateDashCandidateComponent } from "./candidate/private-dash-candidate/private-dash-candidate.component";
import { OfferCandidateComponent } from "./candidate/offer-candidate/offer-candidate.component";
import { AuthChoiceComponent } from './auth/auth-choice/auth-choice.component';
import { SignupCandidateComponent } from './auth/signup-candidate/signup-candidate.component';
import { CandidateProfileComponent } from "./candidate/candidate-profile/candidate-profile.component";

const routes: Routes = [
  { path: 'auth/:type', component: AuthChoiceComponent },
  { path: 'auth/login/:type', component: LoginComponent },
  { path: 'auth/signup/company', component: SignUpComponent },
  { path: 'auth/signup/candidate', component: SignupCandidateComponent },
  { path: 'public/hero', component: HeroComponent },
  {
    path: 'private',
    loadChildren: () => import('./private/private.module').then((m) => m.PrivateModule),
  },
  {
    path: 'candidate',
    loadChildren: () => import('./candidate/candidate.module')
      .then((m) => m.CandidateModule),
  },
  { path: '**', redirectTo: 'public/hero' }, // Default route
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgClass],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
