import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getAccessToken();

    if (accessToken) {
      const cloned = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return next.handle(cloned);
    } else {
      // No access token found
      if (
        this.router.url.includes('login')
        || this.router.url.includes('signup')
        || this.router.url.includes('auth')
      ) {
        // Already on the login page, do nothing
        return next.handle(request);
      } else {
        // Navigate to the login page
        this.router.navigate(['/auth/login/candidate']);
        return new Observable(); // Prevent the original request from being sent
      }
    }
  }
}