import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AuthModule } from './auth/auth.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './helpers/token.interceptor';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlatpickrModule } from "angularx-flatpickr";
import { FullCalendarModule } from "@fullcalendar/angular";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { RatingModule } from "ngx-bootstrap/rating";
import { ModalModule } from "ngx-bootstrap/modal";
import { MaterialModule } from './material/material.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    AuthModule,
    ReactiveFormsModule,
    AuthModule,
    BrowserAnimationsModule,
    FlatpickrModule.forRoot(),
    FullCalendarModule,
    BrowserModule,
    FullCalendarModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    RatingModule.forRoot(),
    MaterialModule
  ],
  exports: [BrowserModule, AppRoutingModule, NgApexchartsModule,MaterialModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent],
})
export class AppModule { }
