import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-auth-choice',
  templateUrl: './auth-choice.component.html',
  styleUrls: ['./auth-choice.component.scss']
})
export class AuthChoiceComponent implements OnInit {


  constructor(
    private authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private location: Location) {
    if (this.authService.isAuthenticated()) {
      const payload = this.authService.getDecodedAccessToken()
      if (payload?.company?._id) this.router.navigate(['/private/dashboard']);
      else if (payload?.candidate?._id) this.router.navigate(['/candidate/dashboard-candidate']);
      else {
        console.error('something went wrong + back() + constructor' + AuthChoiceComponent.name)
      }
    }
  }

  paramValue: string = ""
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // Access route parameters here
      const paramValue = params['type']; // replace 'paramName' with the actual parameter name
      if (!paramValue) {
        console.error('something went wrong + back()' + AuthChoiceComponent.name)
        this.location.back()
      }
      // Perform actions based on the parameter value
      console.log('Route parameter value:', paramValue);
      this.paramValue = paramValue
    });
  }
  navigateTo(type?: string) {
    this.router.navigate([`/auth/${this.paramValue}/${type}`]);
  }

  navigateToHomePage() {
    this.router.navigate(['/public/hero']);
  }
}
