import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }
  navigateToLogin(type?: string) {
    this.router.navigate([`/auth/login/${type}`]);
  }

  navigateToAuth(type: string) {
    this.router.navigate(['/auth/' + type]);
  }
}
