<div class="header d-flex justify-content-end">
  <div class="d-flex">
    <div class="input-group search-group me-4 position-relative dropdown-notif">
        <span class="input-group-text">
            <em class="icon-loop fs-23"></em>
          </span>
      <input type="text"  class="form-control search-input" placeholder="Search..." id="dropdownSearch" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

       <div class="dropdown-menu" aria-labelledby="dropdownSearch">
         <a class="dropdown-item" href="">
           <div class="d-flex align-items-center">
             <div class="d-flex">
               <span class="font-bold fs-20 text-primary">Goldie Doe</span>
               <p class="font-regular fs-15 px-1 pt-1">
                  has applied for user interface designer
               </p>
             </div>
             <div class="d-flex justify-content-center align-items-center px-2">
               <em class="icon-chevron-up fs-22 cursor-pointer"></em>
             </div>
           </div>
         </a>
       </div>

     </div>
     <div class="dropdown-notif h-fit-content">
       <button class="btn dropdown-toggle" type="button" id="dropdownNotification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <em class="text-white icon-bell fs-25"></em>
       </button>
       <div class="dropdown-menu" aria-labelledby="dropdownNotification">
         <a class="dropdown-item" href="">
           <div class="d-flex align-items-center">
             <div class="d-flex">
                <img src="./assets/img/user-2.png" alt="user">
                 <p class="font-regular fs-15 ps-3 pe-3 pt-1">
                   <span class="font-bold ">Goldie Doe</span>  has applied for user interface designer
                 </p>
             </div>
             <div class="d-flex justify-content-center align-items-center px-2">
               <em class="icon-burger fs-22 cursor-pointer"></em>
             </div>
           </div>
         </a>
         <a class="dropdown-item" href="">
           <div class="d-flex align-items-center">
             <div class="d-flex">
               <img src="./assets/img/user-2.png" alt="user">
               <p class="font-regular fs-15 ps-3 pe-3 pt-1">
                 <span class="font-bold ">Goldie Doe</span>  has applied for user interface designer
               </p>
             </div>
             <div class="d-flex justify-content-center align-items-center px-2">
               <em class="icon-burger fs-22 cursor-pointer"></em>
             </div>
           </div>
         </a>
         <a class="dropdown-item" href="">
           <div class="d-flex align-items-center">
             <div class="d-flex">
               <img src="./assets/img/user-2.png" alt="user">
               <p class="font-regular fs-15 ps-3 pe-3 pt-1">
                 <span class="font-bold ">Goldie Doe</span>  has applied for user interface designer
               </p>
             </div>
             <div class="d-flex justify-content-center align-items-center px-2">
               <em class="icon-burger fs-22 cursor-pointer"></em>
             </div>
           </div>
         </a>
       </div>
     </div>
    <div>
      <em class="icon-logout text-white fs-22 cursor-pointer" (click)="logout()">
      </em>
    </div>
   </div>
</div>

