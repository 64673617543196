import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICompany } from '../models/company.models';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    private apiUrl = environment.apiUrl + '/company';

    constructor(private http: HttpClient) { }

    getAllCompanies(skip: string, limit: string): Observable<any> {
        return this.http.get<{ data: ICompany, success: boolean }>(`${this.apiUrl}/?limit=${limit}&skip=${skip}`);
    }

    getCompanyById(id: string): Observable<ICompany> {
        return this.http.get<ICompany>(`${this.apiUrl}/${id}`);
    }

    updateCompany(id: string, updatedCompany: Partial<ICompany>): Observable<ICompany | null> {
        return this.http.put<ICompany>(`${this.apiUrl}/${id}`, updatedCompany);
    }

    uploadCompanyImage(companyId: string | undefined, picture: File): Observable<any> | any {
        if (companyId) {
            const formData: FormData = new FormData();
            formData.append('picture', picture, picture.name);

            return this.http.post<any>(
                `${this.apiUrl}/${companyId}/upload/profileImg`,
                formData
            );
        }
    }

    deleteCompany(id: string): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/${id}`);
    }
}
