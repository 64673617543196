<div class="main"  [ngClass]="{
  'isPrivate': router.url.startsWith('/private/') || router.url.startsWith('/candidate/'),
  'd-flex': router.url.startsWith('/private/') || router.url.startsWith('/candidate/'),
  'isPublic': !router.url.startsWith('/private/') || router.url.startsWith('/candidate/'),
  'opened-sidebar': sideToggle,
  'closed-sidebar': !sideToggle
}">
  <div class="toggle-menu position-fixed mb-2">
    <button (click)="toggleSide()" class="btn toggle-menu-btn mt-3 mb-md-2">
      <em class="icon-list-center text-white fs-18"></em>
    </button>
  </div>
  <div class="left-sidebar" *ngIf="router.url.startsWith('/private/') || router.url.startsWith('/candidate/')">
    <div class="close-menu">
      <button (click)="toggleSide()" class="btn close-menu-btn">
        <em class="icon-close text-white fs-18"></em>
      </button>
    </div>
    <app-side-bar (linkClicked)="onLinkClicked()"></app-side-bar>
  </div>
  <div class="main-content">
    <app-header  *ngIf="router.url.startsWith('/private/') || router.url.startsWith('/candidate/') "></app-header>
    <router-outlet></router-outlet>
  </div>
</div>
