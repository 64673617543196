import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthResponseDto } from '../dto/authRespDto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { jwtDecode } from "jwt-decode";
import { ICompany } from '../models/company.models';
import { ICandidate } from '../models/candidate.models';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient,
  ) { }

  login(username: string, password: string): Observable<AuthResponseDto> {
    const loginData = {
      email: username,
      password: password,
    };

    return this.http.post<AuthResponseDto>(`${this.apiUrl}/auth/login`, loginData);
  }

  private accessTokenKey = 'accessToken';


  isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  getAccessToken(): string | null {
    return localStorage.getItem(this.accessTokenKey);
  }

  getDecodedAccessToken(): { company?: ICompany, candidate?: ICandidate, iat?: number } | null {
    const token = localStorage.getItem(this.accessTokenKey);
    return token ? jwtDecode(token) : null;
  }

  setAccessToken(token: string): void {
    localStorage.setItem(this.accessTokenKey, token);
  }

  removeAccessToken(): void {
    localStorage.removeItem(this.accessTokenKey);
  }

  registerCompany(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/auth/register/company`, data);
  }

  registerCandidate(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/auth/register/user`, data);
  }
}
